@tailwind base;
@tailwind components;
@tailwind utilities;

p, span, h1, h2, h3, h4, h5, h6, a, li, ul, ol, button, input, textarea, label, select, option, small, strong, b, i, em, u, s, sub, sup, abbr, address, cite, code, dfn, kbd, q, samp, tt, var, bdo, big, del, dfn, ins, small, span, img, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video, button, input, optgroup, select, textarea {
    font-family: 'Nunito', sans-serif;
}

.bg-pattern {
    background-color: white;
    background-image: url("./pattern.svg");
    background-repeat: repeat;
}

@keyframes gradient {
    0% {
      background-position: 0% 50%;
      transform: rotate(0deg);
    }
    50% {
      background-position: 100% 50%;
      transform: rotate(360deg);
    }
    100% {
      background-position: 0% 50%;
      transform: rotate(720deg);
    }
  }

  .animated-gradient {
    background-size: 200% 200%;
    animation: gradient 3s ease infinite;
  }
